<template>
    <Breadcrumb page-title="Submit Credit Memo" page-section="Pages"> </Breadcrumb>
     <section class="content">
        <div class="box">
           
        
            <div class="box-body">
                <p>

This form is used to submit a credit memo request to Finance. You will receive an automated acknowledgment after your request is submitted and will receive an email when the credit memo has been approved and processed.

The list of approvers is based on the largest credit memo amount. Credits less than $5,000 may be approved by a supervisor. Requests between $5,000 and $25,000 require a manager approval. Requests greater than $25,000 require an officer or director approval.
</p>
                <div class="box box-primary">
                    <div class="box-header with-border">
                      <h3 class="box-title">Enter Biller &amp; Reason Details</h3>
                    </div>
                    <div class="box-body"> 
                      <div class="row">
                        <div class="col-md-12 form-horizontal">
                          <div class="form-group">
                            <label class="col-12 control-label text-left"></label>
                          </div>  
                          <div class="form-group">
                            <label class="col-4 col-md-2 control-label text-left">Biller Company Name</label>
                            <div class="col-8 col-md-6">
                              <input type="text" class="form-control" disabled="">
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-4 col-md-2 control-label text-left">B‎iller Code</label>
                            <div class="col-8 col-md-6">
                              <input type="text" class="form-control" disabled="">
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-4 col-md-2 control-label text-left">Shipment Type</label>
                            <div class="col-8 col-md-6">
                              <select class="form-control" disabled=""><option label="" value="">Select a value</option></select>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-4 col-md-2 control-label text-left">Reason Category</label>
                            <div class="col-8 col-md-6">
                              <select class="form-control" disabled=""><option label="" value="">Select a value</option></select>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-4 col-md-2 control-label text-left">Reason Description</label>
                            <div class="col-8 col-md-6">
                              <textarea class="form-control" disabled=""></textarea>
                            </div>
                          </div>
                        </div>
                      </div>   
                    </div> 
                  </div>

                  <div class="box box-primary">
                    <div class="box-header with-border">
                      <h3 class="box-title">Enter Credit Details</h3>
                    </div>
                    <div class="box-body"> 
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row"> 
                            <div class="col-md-3">
                              <div class="row form-group">
                                <label class="col-12 control-label text-left">Pro Number</label>
                                <div class="col-12">
                                  <input type="text" class="form-control" disabled="" value="">
                                </div>
                              </div>
                            </div> 
                            <div class="col-md-3">
                              <div class="row form-group">
                                <label class="col-12 control-label text-left">Credit Memo Amount</label>
                                <div class="col-12">
                                  <input type="text" class="form-control" disabled="" value="">
                                </div>
                              </div>
                            </div> 
                            <div class="col-md-4 col-lg-3">
                              <div class="row form-group">
                                <label class="col-12 control-label text-left">Shipment Date</label>
                                <div class="col-12">
                                  <input type="text" class="form-control" disabled="" value="">
                                </div>
                              </div>
                            </div> 
                            <div class="col-md-2 "><label class="col-12 control-label text-left">&nbsp;</label><button class="icon-button button-delete inline-delete" type="button"><i class="fa fa-times-circle"></i></button></div>
                          </div> 
                          <div class="row">
                            <div class="col-md-12 form-horizontal">
                                <div class="form-group">
                                    <label class="col-4 col-md-2 control-label text-left">Pro #(Only one credit per pro # is allowed)</label>
                                    <div class="col-md-4">
                                        <input type="text" class="form-control ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched"><!--v-if-->
                                    </div>
                                    <div class="col-md-4"><button type="button" class="btn btn-info btn-flat">Add <span style=""></span>Pro</button></div>
                                </div>
                            </div>
                           </div>
                           <div class="row form-group mt-2 has-error">
                            <label class="col-4  col-md-2 control-label text-left">Approver</label>
                            <div class="col-8 col-md-4">
                                <select class="form-control">
                                  <option value="">Select Approver</option>
                                </select>
                                <div class="invalid-feedback"><span>Approver is required</span></div>
                            </div>
                           </div>

 
                        </div>
                      </div>   
                    </div> 
                  </div>
                  <div class="row mtop-15">
                    <div class="col-md-12">
                      <button type="button" class="btn btn-primary margin-r-5">Approve Credit</button>
                      <button type="button" class="btn btn-danger margin-r-5">Reject Credit</button>
                    </div>
                  </div>
            </div>
        </div>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
  
   export default {
     name: 'Credit Memo Approval Form',
     components: {
       Breadcrumb,
       
     },
  data: function(){
      return{
        segment1 : false,
        segment2 : false,
        segment3 : false,
        segment4 : false
      }
         
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>